<template>
  <div>

    <div class="form">

      <div class="box">
        <h1>Gerador de QrCode Pix</h1>
        <p>Esta é uma ferramenta para facilitar a geração dos seus QrCodes para pagamentos com Pix.</p>
        <p><a href="https://github.com/ceciliadeveza/gerarqrcodepix" target="_blank">Acesse o repositório no Github</a> e entenda como você pode utilizar diretamente em seu site, de forma gratuita e simplificada.</p>
      </div>

      <div class="box">

        <div class="personal_data">
          <div class="name">
            <label class="title required">Seu nome completo</label>
            <input type="text" v-model="name" maxLength="25" minlength="1" /><br />
          </div>

          <div class="city">
            <label class="title required">Sua cidade</label>
            <input type="text" v-model="city" maxLength="15" minlength="1" /><br />
          </div>

          <div class="zipcode">
            <label class="title">Seu CEP</label>
            <input type="text" maxLength="9" v-model="zipcode" v-mask="['#####-###']" /><br />
          </div>
        </div>

        <div class="code_type">
          <h2 class="required">O que você deseja gerar?</h2>

          <label for="estatico" class="radio">
            <input type="radio" id="estatico" name="qrcode" value="estatico" v-model="choosedQrcode">
            QrCode estático
          </label>

          <label for="dinamico" class="radio">
            <input type="radio" id="dinamico" name="qrcode" value="dinamico" v-model="choosedQrcode">
            QrCode dinâmico
          </label>
        </div>

          <div class="payload" v-if="choosedQrcode == 'dinamico'">
            <label for="payload" class="title required">Payload retornado pela API</label>
            <input id="payload" type="text" name="" v-model="payload" maxLength="77" />
            <br />
          </div>

          <div class="txid" v-if="choosedQrcode == 'estatico'">
            <h2>Identificador da transação</h2>
            <div class="value">
              <input type="text" minLength="5" maxLength="25" v-mask="['XXXXXXXXXXXXXXXXXXXXXXXXX']" v-model="txid" /><br />
            </div>
          </div>

          <div class="associate_value" v-if="choosedQrcode == 'estatico'">
            <h2 class="required">Deseja definir um valor, ou o pagador pode definir qualquer um?</h2>

            <label for="yes" class="radio">
              <input type="radio" id="yes" name="value" value="yes" v-model="associateValue">
              Associar valor
            </label>

            <label for="no" class="radio">
              <input type="radio" id="no" name="value" value="no" v-model="associateValue">
              Não associar valor
            </label>

            <div class="value">
              <input type="text" minLength="3" v-model.lazy="value" v-money="money" v-if="associateValue == 'yes'" /><br />
            </div>
          </div>

          <div class="key_pix" v-if="choosedQrcode == 'estatico'">
            <h2 class="required">Sua chave Pix</h2>

            <div class="types">
              <div class="email">
                <label for="email" class="radio">
                  <input type="radio" id="email" name="choosedKey" value="email" v-model="choosedKey">
                  E-mail
                </label>
                <input type="text" v-model="key_email" maxLength="77" v-if="choosedKey == 'email'" />
              </div>

              <div class="phone">
                <label for="phone" class="radio">
                  <input type="radio" id="phone" name="choosedKey" value="phone" v-model="choosedKey">
                  Telefone
                </label>
                <input type="text" v-mask="['(##) #####-####']" v-model="key_phone" maxLength="77" v-if="choosedKey == 'phone'" />
              </div>

              <div class="document">
                <label for="document" class="radio">
                  <input type="radio" id="document" name="choosedKey" value="document" v-model="choosedKey">
                  CPF / CNPJ
                </label>
                <input type="text" v-mask="['###.###.###-##', '##.###.###/####-##']" maxLength="77" v-if="choosedKey == 'document'" v-model="key_document" />
              </div>

              <div class="random">
                <label for="random" class="radio">
                  <input type="radio" id="random" name="choosedKey" value="random" v-model="choosedKey">
                  Chave aleatória
                </label>
                <input type="text" v-mask="['XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX']" maxLength="77" v-model="key_random" v-if="choosedKey == 'random'" />
              </div>
            </div>
          </div>
      </div>
    </div>

    <div class="result">
      <h3>Seu QrCode Pix</h3>
      <div class="qrcode" v-if="!isValid()">
        <svg width="105" height="105" viewBox="0 0 105 105" xmlns="http://www.w3.org/2000/svg" fill="#fd6746">
          <circle cx="12.5" cy="12.5" r="12.5">
              <animate attributeName="fill-opacity"
               begin="0s" dur="1s"
               values="1;.2;1" calcMode="linear"
               repeatCount="indefinite" />
          </circle>
          <circle cx="12.5" cy="52.5" r="12.5" fill-opacity=".5">
              <animate attributeName="fill-opacity"
               begin="100ms" dur="1s"
               values="1;.2;1" calcMode="linear"
               repeatCount="indefinite" />
          </circle>
          <circle cx="52.5" cy="12.5" r="12.5">
              <animate attributeName="fill-opacity"
               begin="300ms" dur="1s"
               values="1;.2;1" calcMode="linear"
               repeatCount="indefinite" />
          </circle>
          <circle cx="52.5" cy="52.5" r="12.5">
              <animate attributeName="fill-opacity"
               begin="600ms" dur="1s"
               values="1;.2;1" calcMode="linear"
               repeatCount="indefinite" />
          </circle>
          <circle cx="92.5" cy="12.5" r="12.5">
              <animate attributeName="fill-opacity"
               begin="800ms" dur="1s"
               values="1;.2;1" calcMode="linear"
               repeatCount="indefinite" />
          </circle>
          <circle cx="92.5" cy="52.5" r="12.5">
              <animate attributeName="fill-opacity"
               begin="400ms" dur="1s"
               values="1;.2;1" calcMode="linear"
               repeatCount="indefinite" />
          </circle>
          <circle cx="12.5" cy="92.5" r="12.5">
              <animate attributeName="fill-opacity"
               begin="700ms" dur="1s"
               values="1;.2;1" calcMode="linear"
               repeatCount="indefinite" />
          </circle>
          <circle cx="52.5" cy="92.5" r="12.5">
              <animate attributeName="fill-opacity"
               begin="500ms" dur="1s"
               values="1;.2;1" calcMode="linear"
               repeatCount="indefinite" />
          </circle>
          <circle cx="92.5" cy="92.5" r="12.5">
              <animate attributeName="fill-opacity"
               begin="200ms" dur="1s"
               values="1;.2;1" calcMode="linear"
               repeatCount="indefinite" />
          </circle>
      </svg>
        <p>Preencha todos os dados obrigatórios para visualizar o QrCode.</p>
      </div>
      <div class="qrcode" v-if="isValid()">
        <vue-qrcode :value="convert" :width="250" />
      </div>

      <div v-if="isValid()" class="copia_e_cola">
        <h3>Seu BrCode Pix (copia e cola)</h3>
        <div>{{ convert }}</div>
      </div>

    </div>
  </div>
</template>
<script>
import VueQrcode from 'vue-qrcode'
import { crc16 } from 'easy-crc'
import {mask} from 'vue-the-mask'
import {VMoney} from 'v-money'

export default {
  directives: {mask, money: VMoney},
  components: {
    VueQrcode
  },
  data() {
    return {
      message: '',
      choosedQrcode: 'estatico',
      choosedKey: 'email',
      associateValue: 'no',
      payload: '',
      value: '',
      name: '',
      zipcode: '',
      city: '',
      response: '',
      submitStatus: 'INVALID',
      key_pix: '',
      key_email: '',
      key_phone: '',
      key_document: '',
      key_random: '',
      txid: '',
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false
      }
    }
  },
  methods: {
    isValid() {
      let valid = (this.name != '') && (this.city != '');

      if(this.choosedQrcode == 'dinamico'){
        valid &= this.payload != '';
      } else {
        switch(this.choosedKey) {
          case 'phone':{
            valid &= this.key_phone != '';
            break;
          }
          case 'email':{
            valid &= this.key_email != '';
            break;
          }
          case 'document':{
            valid &= this.key_document != '';
            break;
          }
          case 'random':{
            valid &= this.key_random != '';
            break;
          }
          default: {
            break;
          }
        }
      }

      return valid;
    },
    define_payload() {
      return '000201';
    },
    point_initiation() {
      return '010212';
    },
    merchant_account() {

      let account = '0014BR.GOV.BCB.PIX';

      if (this.choosedQrcode == 'estatico'){
        var only_number = /[^0-9]/gi;
        switch(this.choosedKey) {
          case 'phone':{
            let phone = '+55' + this.key_phone.replace(only_number, "");
            account += '01' + phone.length.toString().padStart(2, '0') + phone;  
            break;
          }
          case 'email':{
            account += '01' + this.key_email.length.toString().padStart(2, '0') + this.key_email.toUpperCase();  
            break;
          }
          case 'document':{
            let doc = this.key_document.replace(only_number, "");
            account += '01' + doc.length.toString().padStart(2, '0') + doc;  
            break;
          }
          case 'random':{
            account += '01' + this.key_random.length.toString().padStart(2, '0') + this.key_random.toUpperCase();  
            break;
          }
          default: {
            break;
          }
        }
      } else {
        account += '25' + this.payload.length.toString().padStart(2, '0') + this.payload.toUpperCase();
      }

      return '26' + account.length + account;
    },
    mcc() {
      return '52040000';
    },
    transaction_currency() {
      return '5303986';
    },
    transaction_amount() {
      if(this.associateValue == 'yes' && this.choosedQrcode == 'estatico') {

        let total = this.value.replace(/[^0-9]/gi, "")
        let cents = total.substring(total.length - 2)
        let value = total.substring(0, total.length - 2)
        let result = value + '.' + cents;
      
        return '54' + result.length.toString().padStart(2, '0') + result;
      }
      return '';
    },
    country_code() {
      return '5802BR';
    },
    merchant_name() {
      let name = this.name.normalize("NFD").replace(/[^a-zA-Zs ]/g, "");
      return "59" + name.length.toString().padStart(2, '0') + name; 
    },
    merchant_city() {
      let city = this.city.normalize("NFD").replace(/[^a-zA-Zs ]/g, "");
      return '60' + city.length.toString().padStart(2, '0') + city;  
    },
    postal_code() {
      if(this.zipcode != '') {
        return '6108' + this.zipcode;  
      }
      return '';
    },
    additional_data_field() {
      if (this.choosedQrcode == 'estatico' && this.txid.length > 5 && this.txid.length <= 25) {
        let data_field = '';
        data_field = '05' + this.txid.length.toString().padStart(2, '0') + this.txid;
        return '62' + data_field.length + data_field;
      }
      return '62070503***';
    },
    crc_ti(){

      let data = Buffer.from(this.define_payload() + 
                          this.merchant_account() +
                          this.mcc() +
                          this.transaction_currency() +
                          this.transaction_amount() +
                          this.country_code() +
                          this.merchant_name() +
                          this.merchant_city() +
                          this.postal_code() +
                          this.additional_data_field() + '6304');

      let checksum = crc16('CCITT-FALSE', data);
      return '6304' + checksum.toString(16).padStart(4, '0').toUpperCase();
    }
  },
  computed: {
    
    convert () {
      return this.define_payload() + 
              this.merchant_account() +
              this.mcc() +
              this.transaction_currency() +
              this.transaction_amount() +
              this.country_code() +
              this.merchant_name() +
              this.merchant_city() +
              this.postal_code() +
              this.additional_data_field() +
              this.crc_ti();
    }
  }
}
</script>